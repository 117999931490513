<template>
    <b-modal
      id="bills-to-receive-modal"
      ref="billsToReceiveModal"
      hide-header
      hide-footer
      centered size="lg"
      @hidden="onHidden"
      @show="onShow"      
    >
      <div class="modal-header">
        <div class="modal-title" v-if="!form.id">Nova entrada</div>
        <div class="modal-title" v-else>Editar entrada</div>
        <span class="icon-box"><Close class="icon stroke" @click="closeModal"  /></span>
      </div>
      <div class="around-content">
        <validation-observer ref="billToReceiveForm">
          <div class="row">
  
            <div class="col-4">
                <label for="field_date" id="title-styles">
                  Data do atendimento
                  <span class="help" id="help">(opcional)</span>
                </label>
                <date-picker
                  class="full"
                  v-model="form.service_date"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/AAAA"
                  :clearable="false"
                  :lang="langDatePicker"
                ></date-picker>
            </div>
  
            <div class="col-8">
              <label for="category" id="title-styles">Categoria</label>
              <CategorySelect
                v-model="form.category"
                :categories="categoriesFiltered"
                @changeCategory="changeCategory"
                :disabled="form.id !== null || !!categorySelected"
                />
              <div v-if="validated && !form.category" class="custom-invalid-feedback">Campo obrigatório</div>
            </div>
  
            <div class="col-12">
              <hr id="hr" />
            </div>
  
            <div v-if="(!form.category || !['Depósito'].includes(form.category.name)) && showBeneficiaryField" class="col-6">
              <BeneficiarySelect
                v-model="form.beneficiary"
                :value="form.beneficiary"
                :options="beneficiaries"
                optional
                label="Favorecido"
              />
            </div>
  
            <div v-if="!form.category || !['Depósito'].includes(form.category.name)" class="col">
              <PatientInput
                required
                v-model="form.patient"
                :disabled="disabledPatientInput"
                :error="validated && !form.patient"
                @select="onSelectPatient"
              />
            </div>

            <div v-if="showFieldPaymentVendorId" class="col-12">
              <b-form-group>
                <label for="payment_vendor">
                  ID do pagamento
                  <span class="help" id="help">(opcional)</span>
                </label>
                <b-form-input
                  v-model="form.payment_vendor_id"
                  type="text"
                  placeholder="Descrever"
                />
              </b-form-group>

            </div>
  
  
            <div class="col-12" v-if="showFieldAppointment">
              <b-form-group>
                <label for="field_appointment" id="title-styles">Atendimento</label>
                <ProcedureGroupSelect
                  variant="appointment"
                  v-model="form.appointment"
                  :options="appointments"
                  placeholder="Selecionar"
                  :disabled="editMode"
                  :empty-message="form.patient && !appointments.length ? 'Nenhum atendimento encontrado' : 'Selecione um paciente para ver seus procedimentos aqui'"
                  @input="getProceduresFromAppointment"
                />
                <div v-if="validated && !form.appointment" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>
            <div class="col-12" v-if="form.appointment && form.appointment.appointment_procedures && form.appointment.appointment_procedures.length">
              <BillsToReceiveProcedures
                :procedures="form.appointment.appointment_procedures"
              />
            </div>
  
            <div id="space-groups" v-if="(!form.category || !['Depósito'].includes(form.category.name)) && showFieldHealthPlan">
              <div class="col-6">
                <b-form-group>
                  <label for="health_plan" id="title-styles">Convênio</label>
                  <multiselect
                    id="health_plan"
                    v-model="form.health_plan"
                    track-by="id"
                    label="fantasy_name"
                    placeholder="Selecionar"
                    :options="healthPlans"
                    :searchable="true"
                    :allow-empty="false"
                    :option-height="40"
                    :showLabels="false"
                    :showNoResults="true"
                    class="with-border"
                    :disabled="blockEditHealthPlan || editMode"
                    @select="getPlans"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.fantasy_name }}
                    </template>
                    <template slot="noOptions">
                      <div>Digite para pesquisar seus convênios...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhum convênio encontrado...</div>
                    </template>
                  </multiselect>
                  <div v-if="validated && form.category && !['Depósito'].includes(form.category.name) && !form.health_plan && !isPatientCreditCategory" class="custom-invalid-feedback">Campo obrigatório</div>
                </b-form-group>
              </div>
  
              <div class="col-6">
                <b-form-group>
                  <label for="plan" id="title-styles">
                    Plano
                    <span class="help" id="help">(opcional)</span>
                  </label>
                  <multiselect
                    id="plan"
                    v-model="form.plan"
                    track-by="id"
                    label="name"
                    placeholder="Selecionar"
                    :options="auxPlans"
                    :searchable="true"
                    :allow-empty="false"
                    :option-height="40"
                    :showLabels="false"
                    :showNoResults="true"
                    :disabled="!auxPlans.length || blockEditHealthPlan || editMode"
                    class="with-border"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.name }}
                    </template>
                    <template slot="noOptions">
                      <div>Selecione um convênio para pesquisar os planos...</div>
                    </template>
                    <template slot="noResult">
                      <div>Nenhum plano encontrado...</div>
                    </template>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
  
            <div class="col" v-if="showFieldAmount">
              <b-form-group>
                <label for="field_amount" id="title-styles">Valor total</label>
                <money
                  class="form-control"
                  :value="form.amount"
                  :disabled="editMode"
                  @input="handleAmount"
                  :id="parseFloat(form.amount) < 0 ? 'red-border' : null"
                />
                <div v-if="validated && !form.amount && !isPatientCreditCategory" class="custom-invalid-feedback">Campo obrigatório</div>
                <div v-if="parseFloat(form.amount) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
              </b-form-group>
            </div>

            <div class="col-3" v-if="creditAmount > 0 && !!canUsePatientCredit && !isPatientCreditCategory">
              <b-form-group>
                <label for="field_credit_discount" id="title-styles">Desconto crédito</label>
                <money
                  class="form-control"
                  disabled
                  v-model.lazy="form.creditDiscount"
                />
              </b-form-group>

            </div>
  
            <div class="col-3" v-if="showFieldDiscount">
              <b-form-group>
                <label for="field_discount" id="title-styles">Desconto </label>
                <span class="help" id="help"> (opcional)</span>
                <money
                  v-if="form.currency.value === 'R$'"
                  class="form-control"
                  v-model.lazy="form.discount"
                />

                <b-form-input
                  v-else
                  autocomplete="off"
                  class="form-control"
                  v-model="form.discount"
                  :disabled="editMode"
                  :id="parseFloat(form.amount) < 0 ? 'red-border' : null"
                />
                <div v-if="parseFloat(form.discount) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
              </b-form-group>
            </div>
  
            <div class="col" v-if="showFieldCurrency">
              <b-form-group>
                <label for="currency" id="title-styles">Tipo</label>
                <span v-if="!form.discount" class="help" id="help"> (opcional)</span>
                <multiselect
                  track-by="value"
                  label="label"
                  placeholder="Selecionar"
                  :options="currency"
                  :allow-empty="false"
                  :option-height="40"
                  :showLabels="false"
                  :showNoResults="false"
                  class="with-border"
                  :disabled="editMode"
                  v-model="form.currency"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.label }}
                  </template>
  
                  <template slot="noOptions">
                    Nenhuma opção
                  </template>
  
                  <template slot="noResult">
                    Nenhum resultado
                  </template>
                </multiselect>
                <div v-if="validated && form.discount && !form.currency" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>
  
            <div class="col" v-if="showFieldFinalAmount">
              <b-form-group>
                <label for="field_final_amount" id="title-styles">Valor final</label>
                <money
                  class="form-control"
                  :value="form.final_amount"
                />
                <div v-if="validated && !form.final_amount && !isPatientCreditCategory && !canLoadCreditPatient" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>
  
            <div class="col-12">
              <div id="space-groups-column" class="description">
                <label for="field_description" id="title-styles">
                  Descrição
                  <span v-if="form.category && ['Procedimento', 'Produto'].includes(form.category.name)" class="help" id="help">(opcional)</span>
                </label>
                <b-form-input
                  autocomplete="off"
                  v-model="form.description"
                  placeholder="Adicionar observações"
                />
              </div>
              <div v-if="validated && form.category && !['Procedimento', 'Produto'].includes(form.category.name) && !form.description" class="custom-invalid-feedback">Campo obrigatório</div>
            </div>

            <div class="col-12" v-if="!!canUsePatientCredit && !isPatientCreditCategory && !!form.patient">
							<div class="d-flex justify-content-between align-items-center border rounded px-3 py-1">

								<div class="d-flex flex-row align-items-center justify-content-center m-0">
									<b-form-checkbox
									id="use_patient_credit"
									v-model="usePatientCredit"
									switch
									class="use-patient-credit"
                  :disabled="!patientCredit?.credit > 0 || !!billToReceiveId"
									>
									<span class="label">Crédito do paciente</span>
									</b-form-checkbox>
								
								</div>
								<div class="credit-available" v-if="!billToReceiveId">
									Crédito atual {{ creditFormatted }}
								</div>
                <div v-else>
                  <b-link @click="goPatient">Visualizar crédito do paciente</b-link>
                </div>
							</div>
						</div>

						<div class="col-12 row py-1" v-if="usePatientCredit && canUsePatientCredit && !billToReceiveId">
							<b-form-group class="col-6">
								<label for="credit_amount" class="d-flex flex-row justify-content-between align-itens-center">
                  Valor a ser utilizado
                  <b-form-checkbox
                    id="use_credit_total"
                    v-model="useCreditTotal"
                    class="use-credit-total"
                    :disabled="!usePatientCredit || !!billToReceiveId"
                  >
                    <span class="use-credit__label">
                      Utilizar crédito total
                    </span>
                  </b-form-checkbox>
                </label>
								<money
									class="form-control"
									v-model="creditAmount"
									:disabled="!usePatientCredit || !!billToReceiveId"
                  @onChange="handleChangeCreditAmount"
								/>
							</b-form-group>
							<b-form-group class="col-6" v-if="!billToReceiveId">
								<label for="balance">Saldo</label>
								<money
									class="form-control"
									:value="balanceValue"
									:disabled="true"
								/>
                <div v-if="validated && isCreditBalanceNegative" class="custom-invalid-feedback">
                  O saldo não pode ficar negativo
                </div>
							</b-form-group>
						</div>
  
          </div>
        </validation-observer>
  
        <Payments
          ref="payments"
          :useTax="!form.category || form.category.name !== 'Depósito'"
          :validated="validated"
          :bill="form"
          :totalAmount="getFinalAmount()"
          :isCreditCategory="isPatientCreditCategory"
        />

        <div
          class="checkbox-wrapper"
          v-show-feature="'nota-fiscal'"
          v-if="showFeature('Notas Fiscais') && !issuedNFSe"
        >
          <Check :value="issueInvoice" @input="handlerIssueInvoice" />
          <div>Desejo emitir uma NFS-e (Nota Fiscal de Serviço Eletrônico)</div>
        </div>
  
        <div :class="{ 'wrapper-button': true, deletable: form.id }">
          <b-button
            class="wh-button text-primary font-weight-normal"
            variant="outline"
            @click="openBillAttachmentsModal"
            v-if="isPatientCreditCategory"
          >
          <span>
            <Clip/> Anexar arquivo
          </span>
          </b-button>
          <b-button
            v-if="form.id"
            v-can="'FiConRec5'"
            class="wh-button danger"
            variant="danger-outline"
            size="lg"
            @click="onDeleteClick"
          >
              Apagar conta
          </b-button>
          <div>
            <b-button
              :v-can="canDeleteBill"
              @click="downloadNFSePDF"
              class="view-invoice__button mr-3"
              v-if="canShowDeleteBillButton"
            >
              <IssuedNfeIcon class="mr-1"/>
              Visualizar NFS-e
            </b-button>
            <b-button
              v-if="!form.id"
              v-can="'FiConRec2'"
              class="wh-button"
              variant="primary"
              size="lg"
              @click="createBillToReceive"
              :disabled="alreadySent"
            >
              Salvar conta a receber {{ issueInvoice ? 'e emitir NFS-e' : '' }}
            </b-button>
            <b-button
              v-else
              v-can="'FiConRec4'"
              class="wh-button"
              variant="primary"
              size="lg"
              @click="updateBillToReceive"
            >
              Atualizar conta a receber {{ issueInvoice ? 'e emitir NFS-e' : '' }}
            </b-button>
          </div>
        </div>
  
        <DeleteBillToReceiveModal
          :description="form.patient ? form.patient.name : ''"
          :confirmedDelete="confirmedDelete"
        />

        <BillAttachmentsModal
          :bill="form"
          @onHide="onHidden"
          @saveFile="onSaveFileFromBillAttachments"
          v-if="isPatientCreditCategory"
        />
      </div>
    </b-modal>
  </template>
  <script>
  import api from '../api'
  import moment from 'moment'
  import apiNF from '@/modules/nfe/api'
  import { track } from '@/utils/mixPanel'
  import { EventBus } from '@/utils/eventBus'
  import { showFeature, showInvoicingTiss, hasPermissionPatientCreditCategory } from '@/utils/permissionsHelp'
  import { getCurrentClinic } from '@/utils/localStorageManager'
  import { parseNumberToMoney } from '@/utils/moneyHelper'

  export default {
    name: 'BillToReceiveModal',
    components: {
      BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
      BillsToReceiveProcedures: () => import('@/modules/financial/components/BillsToReceiveProcedures'),
      ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
      Close: () => import('@/assets/icons/close.svg'),
      Clip: () => import('@/assets/icons/clip.svg'),
      Check: () => import ('@/components/General/Check'),
      DeleteBillToReceiveModal: () => import('@/components/General/DeleteBillToReceiveModal'),
      PatientInput: () => import('@/components/General/PatientInput'),
      IssuedNfeIcon: () => import('@/assets/icons/nf-issued-icon.svg'),
      Payments: () => import('@/modules/financial/components/Payments'),
      ProcedureGroupSelect: () => import('@/components/General/ProcedureGroupSelect'),
      CategorySelect: () => import('@/modules/financial/components/CategorySelect'),
      BillAttachmentsModal: () => import('@/modules/financial/modals/BillAttachmentsModal')
    },
    props: {
      billToReceiveId: String,
      billToReceive: Object,
      categorySelected: Object,
      patientSelected: Object,
      showFields: Object,
    },
    data() {
      const clinic = getCurrentClinic()
      return {
        billToReceiveCopy: null,
        clinic: clinic,
        clinic_id: clinic.id,
        validated: false,
        showFieldAppointment: false,
        showInfoAppointment: false,
        issueInvoice: false,
        disabledField: false,
        showFieldsInstallment: false,
        errors: {},
        categories: [],
        patients: [],
        issuedNFSe: false,
        beneficiaries: [],
        appointments: [],
        plans: [],
        auxPlans: [],
        paymentMethods: [],
        healthPlans: [],
        procedures: {},
        toDelete: null,
        blockEditHealthPlan: false,
        editMode: false,
        form: this.getDefaultForm(),
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
          }
        },
        healthInsurance: [{ label: 'Convênio', value: 'CONVENIO' }, { label: 'Particular', value: 'PARTICULAR' }],
        currency: [{ label: 'R$', value: 'R$' }, { label: '%', value: '%' }],
        paymentSituation: [
          { label: 'Vencido', value: 'Vencido' },
          { label: 'Pago', value: 'Pago' },
          { label: 'Pendente', value: 'Pendente' },
          { label: 'Parcelado', value: 'Parcelado' }
        ],
        installmentQuantity: [
          { label: '01', value: '01' },
          { label: '02', value: '02' },
          { label: '03', value: '03' },
          { label: '04', value: '04' },
          { label: '05', value: '05' }
        ],
        showBeneficiaryField: true,
        showFieldHealthPlan: true,
        showFieldAmount: true,
        showFieldDiscount: true,
        showFieldCurrency: true,
        showFieldFinalAmount: true,
        showFieldPaymentVendorId: false,
        showFieldCreditDiscount: false,
        patientCreditCategory: null,
			  patientCredit: null,
			  usePatientCredit: false,
			  creditAmount: 0,
        creditPatientCategory: null,
        useCreditTotal: false,
        categoriesReceive: null,
        entriesDefaultBankAccounts: null,
  
      }
    },
    computed: {
      disabledPatientInput() {
        return !this.form.category || this.editMode || !!this.patientSelected
      },
      isPatientCreditCategory() {
        return this.form.category && this.form.category.name === 'Depósito de crédito (paciente)'
      },
      canUsePatientCredit() {
        return this.form.category?.name && this.canLoadCreditPatient
      },
		  creditFormatted() {
		  	return `R$${parseNumberToMoney(this.patientCredit?.credit).trim().split('$')[1] }`
		  },
		  balanceValue() {
		  	return (this.patientCredit?.credit - this.creditAmount) || 0
		  },
      categoriesFiltered() {
        return this.categories.filter(category => {
          if (category.name === 'Depósito de crédito (paciente)') {
            return this.hasPermissionPatientCreditCategory() && this.showFeature('Caução do paciente')
          }
          return true
        })
      }    ,
      canDeleteBill() {
          return {
          patientCredit: 'FiConCred3',
          bill: 'FiConRec5'
        }[this.categoryType]
      } ,
      categoryType() {
        return this.form.category?.name === 'Depósito de crédito (paciente)' ? 'patientCredit' : 'bill'
      }, 
      canShowDeleteBillButton() {
        return this.showFeature('Notas Fiscais') && this.issuedNFSe
      },
      canLoadCreditPatient() {
        return this.showFeature('Caução do paciente') && this.hasPermissionPatientCreditCategory()
      },
      isCreditBalanceNegative() {
        return this.balanceValue < 0
      }
    },
    methods: {
      hasPermissionPatientCreditCategory,
      showFeature,
      showInvoicingTiss,
      getDefaultForm() {
        return {
          id: null,
          type: 'bill_to_receive',
          category: null,
          patient: null,
          beneficiary: null,
          description: null,
          appointment_id: null,
          health_plan: null,
          plan: null,
          service_date: new Date(),
          amount: 0,
          discount: 0,
          currency: { label: '%', value: '%' },
          final_amount: 0,
          alreadySent: false,
          payments: [{
            payment_method: null,
            amount: 0,
            due_date: null,
            bank_account: null,
            installments: [],
          }],
          payment_vendor_id: null,
          billAttachment: null,
          creditAmount: 0,
          creditPatientCategory: null,
          patientCredit: null,
          creditDiscount: 0
        }
      },
      async onShow() {
        const loading = this.$loading.show()
        if (this.showFields) {
          this.showBeneficiaryField = this.showFields?.showBeneficiaryField
          this.showFieldHealthPlan = this.showFields?.showFieldHealthPlan 
          this.showFieldAmount = this.showFields?.showFieldAmount 
          this.showFieldDiscount = this.showFields?.showFieldDiscount 
          this.showFieldCurrency = this.showFields?.showFieldCurrency 
          this.showFieldFinalAmount = this.showFields?.showFieldFinalAmount 
          this.showFieldPaymentVendorId = this.showFields?.showFieldPaymentVendorId
          this.showFieldCreditDiscount = this.showFields?.showFieldCreditDiscount
        }

        if (this.categorySelected && this.canLoadCreditPatient) {
          this.form.category = this.categorySelected
        }
        if (this.patientSelected) {
          this.form.patient = this.patientSelected
        }

        if (this.canLoadCreditPatient && !this.billToReceiveId && !this.isPatientCreditCategory) {
          await this.getPatientDepositCategory()
        }
        this.getDefaultBankAccounts()
        this.getCategories()
        loading.hide()
        if(!this.billToReceiveId) return
        try {
          const res = await this.api.getBill(this.billToReceiveId)
          res.data.nfs_id ? this.issuedNFSe = true : this.issuedNFSe = false
        } catch (err) {
          this.$toast.error(err.message)
        } finally {
          loading.hide()
        }
      },
      handleAmount(value) {
        if (!value || this.editMode) return;
        this.form.amount = value;
      },
      async getCategories() {
        try {
          let newValue = null
          await this.api.getCategories(this.clinic.id, 'Entrada').then(res => {
              this.categoriesReceive = res.data.map(el => ({
              ...el,
              label: `${el.name}`,
              value: `${el.id}`
              }))
              this.categories = res.data.filter(el => !['Procedimento', 'Produto', 'Faturamento TISS'].includes(el.name));
              newValue = res.data.find(
              el => el.default_category_accounts_receivable
              )
            })
          this.form.category = this.form.category ? this.form.category : newValue
          return this.categories
        } catch (error) {
          this.$toast.error(error.message)
          throw new Error(400)
        }
      },
      async getDefaultBankAccounts() {
        try {
          await this.api.getBankAccountBillToReceive(this.clinic.id).then(res => {
          this.entriesDefaultBankAccounts = res.data.find(
            el => el.default_bank_account_entries
          )
        })
        const validPayment = this.form.payments.find(el => el.id)
        if(!validPayment || validPayment === null) {
          this.form.payments[0].bank_account = this.entriesDefaultBankAccounts
        }

        } catch (error) {
          this.$toast.error(error.message)
          throw new Error(400)
        }
      },
      getPatientPlans(patientId, planId) {
        let healthPlan = null;
        let plan = null;
  
        this.api.getPatientHealthPlans(this.clinic.id, patientId, "noTiss")
        .then(res => {
          this.healthPlans = res.data.map(data => data.health_plan);
          this.plans = res.data.filter(data => data.plan_id).map(data => data.plan);
  
          if (this.plans.length === 1) {
            healthPlan = this.healthPlans[0];
            plan = this.plans[0];
          } else if (planId) {
            const data = res.data.find(data => data.plan.id === planId);
            healthPlan = data.health_plan;
            plan = data.plan;
          } 

          if (healthPlan) {
            this.form.health_plan = healthPlan;
            this.form.plan = plan;
          }
        })
        .catch(err => this.$toast.error(err.message));
      },
      async onSelectPatient(patient) {
        if (this.form.category && this.form.category.name === 'Procedimento') {
          this.procedures = {};
          await this.getAppointmentProcedures(patient.id);
        }
        this.getPatientPlans(patient.id);
        if (this.canLoadCreditPatient && !this.isPatientCreditCategory && !this.billToReceiveId) {
          this.getCreditPatient();
        }
      },
      getAppointmentProcedures(patientId) {
        return new Promise((resolve, reject) => {
          this.api.getAppointmentProcedures(this.clinic.id, patientId, 'pending_financial')
          .then(res => {
            this.appointments = res.data;
            resolve(res.data);
          })
          .catch(reject);
        })
      },
      async getProceduresFromAppointment(appointment) {
        this.form.appointment = appointment
        if(appointment.health_plan) {
          this.form.health_plan = appointment.health_plan;
          this.form.plan = appointment.plan;
          this.blockEditHealthPlan = true;
        }
  
        this.form.service_date = moment(appointment.start_datetime).toDate();
        const amount = appointment.appointment_procedures.reduce((acc, procedure) => {
          acc += procedure.amount * parseInt(procedure.quantity);
          return acc;
        }, 0);
        if(!this.form.appointment_id) this.form.amount = amount;
      },
      getPlans(healthPlan) {
        this.plan = null;
        if (!healthPlan) return;
        this.auxPlans = this.plans.filter(plan => plan.health_plan_id === healthPlan.id);
        if (this.auxPlans.length === 1) {
          this.form = { ...this.form, plan: this.auxPlans[0] };
        }
      },
      parseFormData() {
        return {
          type: this.form.type,
          clinic_id: this.clinic.id,
          category_id: this.form.category.id,
          patient_id: this.form.patient?.id ?? null,
          beneficiary_id: this.form.beneficiary ? this.form.beneficiary.id : null,
          health_plan_id: this.form.health_plan ? this.form.health_plan.id : null,
          plan_id: this.form.plan ? this.form.plan.id : null,
          description: this.form.description,
          appointment_id: this.form.appointment ? this.form.appointment.id : null,
          service_date: this.moment(this.form.service_date).format('YYYY-MM-DD'),
          amount: this.getAmount(),
          discount: this.getDiscount(),
          currency: this.getCurrencyDiscount(),
          final_amount: this.getFinalAmount(),
          bill_items: [],
          payments: this.form.payments.map(payment => {
            const paymentPayload = {
              amount: payment.amount,
              due_date: payment.due_date,
              bank_account_id: payment.bank_account.id,
              payment_method_id: payment.payment_method.id,
              installments: payment.installments
            };
            
            if (payment.id) {
              paymentPayload.id = payment.id;
            }


            return paymentPayload
          }),
          creditPatientAmount: this.creditAmount > 0 ? this.creditAmount : null,
          creditPatientCategory: this.creditPatientCategory?.id,
          payment_vendor_id: this.form.payment_vendor_id
        };
      },
      validateValues(){
        const negativePayment = this.form.payments.filter(payment => parseFloat(payment.amount) < 0)
        return (
          (this.isPatientCreditCategory || parseFloat(this.form.amount) > 0) &&
          !negativePayment?.length &&
          (!this.form.discount || (this.form.discount && parseFloat(this.form.discount) > 0))
        )
      },
      async createBillToReceive() {
        if (!this.isValidForm()) return;
        if(!this.validateValues()){
          this.$toast.error('Os valores inseridos na conta devem ser positivos!');
          return
        }

        if(this.alreadySent) return;
        this.alreadySent = true;

        const data = this.parseFormData();

        const isLoading = this.$loading.show();
        try {
          const bill = await this.api.createBill(data);
          if (this.form.file) {
            await this.updateBillToReceiveTISSFile(bill.data.id, this.form.file);
          }

          // if (this.form.billAttachment) {
          //   const fileFormData = new FormData();
          //   fileFormData.append('file', this.form.billAttachment);
          //   fileFormData.append('clinicId', this.clinic.id);
          //   fileFormData.append('billId', bill.data.id);
          //   await api.storeBillAttachment(fileFormData);
          // }
  
          track('conta_a_receber', {
            clinic: this.clinic.name,
            total: this.form.final_amount
          });
  
          this.form.payments.map(payment => {
            track('metodo_pagamento', {
              clinic: this.clinic.name,
              payment_method: payment.payment_method.type,
              total: payment.amount
            });
          })

           if(this.issueInvoice) {
            const issueInvoiceParams = {
              bill_id: bill.data.id,
              servico: {
                aliquota: '',
                valor_servicos: this.form?.final_amount
              },
              tomador: {
                nome: this.form?.patient?.name,
                email: this.form?.patient?.email,
                cnpj_cpf: this.form?.patient?.cpf,
                patient_id: this.form?.patient?.id,
              }
            }

            this.$store.dispatch('nfe/updateNfModalData', issueInvoiceParams)
            this.$store.dispatch('nfe/updateIsPatientDisabled', true)
            this.$bvModal.show('new-invoice-modal')
          }
  
          this.clearForm();
          this.$toast.success('Conta a receber cadastrada com sucesso!');
          setTimeout(() => {
            EventBus.$emit('reloadBillsToReceive');
          }, "200")       
          this.alreadySent = false;
          this.$bvModal.hide('bills-to-receive-modal');

          this.$emit('onHide');
        } catch(err) {
          this.$toast.error(err.message);
          this.alreadySent = false;
        } finally {
          isLoading.hide();
        }
      },
      async updateBillToReceive() {
        if (!this.isValidForm()) return;
        if(!this.validateValues()){
          this.$toast.error('Os valores inseridos na conta devem ser positivos!');
          return
        }

        const data = this.parseFormData();
        const isLoading = this.$loading.show();
        try {
          await this.api.updateBill(this.form.id, data);
          this.$toast.success('Conta a receber atualizada com sucesso!');

           if(this.issueInvoice) {
            const issueInvoiceParams = {
              bill_id: this.form.id,
              servico: {
                aliquota: '',
                valor_servicos: this.form?.final_amount
              },
              tomador: {
                nome: this.form?.patient?.name,
                email: this.form?.patient?.email,
                cnpj_cpf: this.form?.patient?.cpf,
                patient_id: this.form?.patient?.id,
              }
            }

            this.$store.dispatch('nfe/updateNfModalData', issueInvoiceParams)
            this.$store.dispatch('nfe/updateIsPatientDisabled', true)
            this.$bvModal.show('new-invoice-modal')
          }

          this.$bvModal.hide('bills-to-receive-modal')
          setTimeout(() => {
            EventBus.$emit('reloadBillsToReceive');
          }, "200")          
          this.$emit('onHide');
          isLoading.hide();
          this.clearForm();
        } catch(ex) {
          this.$toast.error(ex.message);
          isLoading.hide();
        }
      },
      async onDeleteClick() {
        this.$bvModal.show('delete-bill-to-receive-modal');
      },
      async confirmedDelete(confirm) {
        if (confirm) {
          const isLoading = this.$loading.show();
          try {
            await this.api.deleteBill(this.form.id);
            this.$toast.success('Conta a receber apagada com sucesso');
            isLoading.hide();
            setTimeout(() => {
              EventBus.$emit('reloadBillsToReceive');
            }, "200")
            this.closeModal();
          } catch(ex) {
            this.$toast.error(ex.message);
            isLoading.hide();
          }
        }
        this.$bvModal.hide('delete-bill-to-receive-modal');
      },
      clearForm() {
        this.form = this.getDefaultForm();
        this.procedures =  {};
        this.validated = false;
        this.showFieldAppointment = false;
        this.showFieldsInstallment = false;
        this.showInfoAppointment = false;
        this.editMode = false;
        this.issueInvoice = false;
        this.blockEditHealthPlan = false;
        this.usePatientCredit = false
        this.creditAmount = 0
        this.patientCredit = null
      },
      isValidForm() {
        this.validated = true;
        return this.form.category &&
          (['Depósito'].includes(this.form.category.name) || this.form.patient) &&
          (!this.showFieldAppointment || this.form.appointment) &&
          (this.form.final_amount || this.isPatientCreditCategory || this.canUsePatientCredit) &&
          (['Procedimento', 'Produto'].includes(this.form.category.name) || this.form.description) &&
          this.form.payments.every(payment =>
            payment.payment_method &&
            payment.bank_account &&
            payment.amount &&
            payment.due_date &&
            payment.installments.length
          ) &&
          this.isValidSumAmount() && !this.isCreditBalanceNegative
      },
      isValidSumAmount() {
        let total = 0
				this.form.payments.filter(payment => payment.payment_method.name !== 'Crédito do paciente').forEach(payment => {
					total += payment.amount
				});
        if (this.isPatientCreditCategory) {
          return total > 0
        }

        if (parseFloat(total.toFixed(2)) !== parseFloat(this.form.final_amount.toFixed(2))) {
          this.$toast.warning('A soma dos métodos de pagamento deve ser igual ao valor final')
          return false
        }
        return true
      },
      isValidState(model) {
        return this.errors[model] !== true
      },
      onHidden() {
        this.clearForm();
        this.$emit('onHide')
      },
      closeModal() {
        this.clearForm();
        this.$bvModal.hide('bills-to-receive-modal');
      },
      getBillToReceiveAppointmentRelation(billToReceiveId) {
        if(!billToReceiveId) return
        return new Promise((resolve, reject) => {
          this.api.getBillToReceiveAppointmentRelation(billToReceiveId)
          .then(res => resolve(res.data))
          .catch(reject);
        });
      },
      changeCategory(persistPacient) {
        if(['Procedimento'].includes(this.form.category.name)) {
          if(!persistPacient) this.form.patient = null;
          this.showFieldAppointment = true
          this.disabledField = true
          this.blockEditHealthPlan = false;
        } else {
          this.showFieldAppointment = false
          this.showInfoAppointment = false
          this.disabledField = false
  
          this.appointments = [];
          this.procedures = {};
          // this.form.appointment_procedures = [];
          this.form.patient = null;
        }
      },
      changeInfoAppointment() {
        this.showInfoAppointment = true
      },
      showFieldsInstallmentMethod() {
        if(!this.showFieldsInstallment) {
          this.showFieldsInstallment = true
        } else {
          this.showFieldsInstallment = false
        }
      },
      calculateFinalValue() {
        if (!this.form.discount && !this.form.creditDiscount) {
          this.form.final_amount = this.form.amount
          return
        }

        const amount = this.form.amount;
        const currency = this.form.currency;
        const discount = parseFloat(currency?.value === '%' && this.form?.discount ? this.form?.discount?.replace(',', '.') : this.form.discount);
        const creditDiscount = this.form.creditDiscount ? parseFloat(this.form.creditDiscount) : this.form.creditDiscount;

        if (!amount || (!discount && !creditDiscount) || (discount <= 0 && creditDiscount <= 0) || !currency || !currency.value) {
          this.form.final_amount = amount;
          return;
        }
        
  
        const realDiscount = currency.value === 'R$' ? discount : (amount * discount) / 100;
        this.form.final_amount = amount - realDiscount - creditDiscount;
      },
      handlerIssueInvoice($event) {
        this.issueInvoice = $event
      },
      async downloadNFSePDF() {
        try {
          const { data } = await apiNF.getNFbyId(this.form.nfs_id)
          if (data.caminho_pdf_nota_fiscal) {
            window.open(data.caminho_pdf_nota_fiscal, '_blank')
          } else {
            this.$toast.warning('Nota em processo de autorização, aguarde!')
          }
        } catch (err) {
          this.$toast.error(err.message)
        }
      },
      openBillAttachmentsModal() {
        this.$bvModal.show('bill-attachments-modal')
      },
      onSaveFileFromBillAttachments(file) {
        this.form.billAttachment = file
      },
      setFormFieldsBoolean(value) {
        this.showBeneficiaryField = value
        this.showFieldHealthPlan = value
        this.showFieldAmount = value
        this.showFieldDiscount = value
        this.showFieldCurrency = value
        this.showFieldFinalAmount = value
      },
      // do improvements to not call this method repeatedly
      async getCreditPatient() {
        try {
          if (!this.canLoadCreditPatient) return;

          const category = this.form.id ? this.form.category?.id : this.creditPatientCategory?.id
          if (this.patientCredit || !this.form?.patient?.id || !category) return ;


          const response = await api.getCreditPatient(this.clinic.id, this.form.patient.id, category)

          if (response.data) {
            this.patientCredit = response.data
          }
        } catch (error) {
          console.error(error.message)
        }
		  },
      async getPatientDepositCategory() {
        if (this.clinic?.id && this.showFeature('Caução do paciente')) {
          const response = await api.getCreditPatientCategory(this.clinic.id)

          if (response) {
            this.creditPatientCategory = response.data
          }
        }
      },
      handleChangeCreditAmount(value) {
        this.form.creditDiscount = value
      },
      clearCreditForm() {
        this.usePatientCredit = false
        this.creditAmount = 0
        this.patientCredit = null
      },
      handleUseTotalCredit(value) {
        if (value && this.patientCredit.credit <= this.form.amount) {
          this.creditAmount = this.patientCredit.credit
        } else if (value) {
          this.creditAmount = this.form.amount
        }
      },

      handlePatientCreditPayment(toInsert) {
        if (this.billToReceiveId) return 
        if (toInsert) {
          this.$refs.payments && this.$refs.payments.handleCreditPatientPayment(this.creditAmount)
        } else {
          this.$refs.payments && this.$refs.payments.removeCreditPatientPayment()
        }
      },
      show() {
        this.$refs.billsToReceiveModal.show()
      },
      goPatient() {
        const route = this.$router.resolve(`/pacientes/${this.form.patient.id}/account`)
      window.open(route.href, '_blank');
      },
      getFinalAmount() {
        if (!this.isPatientCreditCategory && !this.usePatientCredit) return this.form.final_amount
        else if(this.usePatientCredit) return this.form.final_amount + this.creditAmount

        const amount = this.form.payments.reduce((acc, payment) => {
          acc += payment.amount
          return acc
        }, 0)

        return amount
      },
      getAmount() {
        if (!this.isPatientCreditCategory) return this.form.amount

        const amount = this.form.payments.reduce((acc, payment) => {
          acc += payment.amount
          return acc
        }, 0)

        return amount
        
      },
      getDiscount() {       
        let discount = 0

        if (this.form.currency?.value === '%') {
          discount = (this.form.discount / 100 * this.form.amount)
        } else if (this.form.currency?.value === 'R$') {
          discount = this.form.discount
        }
        return parseFloat((discount + this.form.creditDiscount).toFixed(2))
      },
      getCurrencyDiscount() {
        if (!this.creditAmount) return this.form.currency ? this.form.currency.value : null

        return 'R$'
      }

    },
    watch: {
      'form.amount': function(newAmount, oldAmount) {
        newAmount && newAmount !== oldAmount && this.calculateFinalValue();
      },
      'form.discount': function(newDiscount, oldDiscount) {
        newDiscount !== oldDiscount && this.calculateFinalValue();
      },
      'form.creditDiscount': function(newDiscount, oldDiscount) {
        newDiscount !== oldDiscount && this.calculateFinalValue();
      },
      'form.currency': function(newCurrency, oldCurrency) {
        newCurrency && newCurrency !== oldCurrency && newCurrency.value && this.calculateFinalValue();
      },
      billToReceiveId: {
        async handler(newValue) {
          if (newValue) {
            this.editMode = true;
            const isLoading = this.$loading.show();
            if (!this.categories.length) {
              this.categories = await this.getCategories();
            }
            const res = await this.api.getBill(newValue);
    
            if (res.data) {
              const form = {
                ...res.data,
                currency: { label: res.data.currency, value: res.data.currency },
                service_date: this.moment(res.data.service_date).toDate(),
              }
    
              if (res.data.appointment) {
                this.appointments = [res.data.appointment];
                if (res.data.appointment.health_plan) {
                  this.healthPlans = [res.data.appointment.health_plan]
                  form.health_plan = res.data.appointment.health_plan
                }
                if (res.data.appointment.plan) {
                  this.plans = [res.data.appointment.plan]
                  this.auxPlans = [res.data.appointment.plan]
                  this.form.plan = res.data.appointment.plan
                }
    
                form.amount = res.data.amount !== null ? res.data.amount : parseFloat(res.data.appointment.procedures_value)
              }
              this.form = form

              if (res.data?.bill_patient_credit) {
                this.creditAmount = res.data.bill_patient_credit.bill_credit.amount
                this.usePatientCredit = true
              }

              if(res.data?.patient?.id){
                this.getPatientPlans(res.data.patient.id);
                this.form.health_plan = this.healthPlans.find(data => data.id === res.data.health_plan_id)
              }
              this.form.discount = this.form.currency.value === 'R$' ? this.form.discount - this.creditAmount : this.form.discount
            }

            isLoading.hide();
          }
          else this.clearForm()
        },
        immediate: true,
        deep: true
      },
      billToReceive: async function(value) {
        if (value) {
          if (!this.categories.length) {
            this.categories = await this.getCategories();
          }
  
          this.form = {
            ...this.form,
            ...value,
            category: this.categories.find(el => el.name === 'Procedimento'),
            currency: { label: value.currency, value: value.currency },
          };
          this.form.discount = this.form.currency.value === 'R$' ? this.form.discount - this.creditAmount : this.form.discount

          this.blockEditHealthPlan = true;
        }
      },
      isPatientCreditCategory: function(value) {
        if (value) {
          this.setFormFieldsBoolean(false)
          this.showFieldPaymentVendorId = true
          this.form.health_plan = null
          this.form.beneficiary = null
          this.form.final_amount = null
          this.form.discount = null
          this.form.plan = null
          this.form.amount = null
          
        } else {
          this.setFormFieldsBoolean(true)
          this.showFieldPaymentVendorId = false
        }
      },
      creditAmount: function(value) {
        this.form.creditDiscount = value
        if (!this.billToReceiveId) {
          this.$refs.payments && this.$refs.payments.setCrediPatientAmount(value)
        }
      },
      'form.patient': function(value) {
        if (!value) {
          this.clearCreditForm()
        }
      },
      'usePatientCredit': function(value) {
        if (value) {
          this.handlePatientCreditPayment(true)
        } else {
          this.form.creditDiscount = 0
          this.creditAmount = 0
          this.useCreditTotal = false
          this.handlePatientCreditPayment(false)
        }
      },
      useCreditTotal: function(value) {
        this.handleUseTotalCredit(value)
      }
    }
  }
</script>

<style lang="scss">
  @import '@/assets/scss/main';

  #bills-to-receive-modal {
    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
  
      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;
  
        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #525C7A;
        }
  
        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }
  
      .tiss-wrapper {
        width: 100%;
        display: inline-flex;
        align-items: baseline;
  
        .input-tiss {
          width: 100%;
        }
  
        .tiss-actions {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          border: 1px solid var(--neutral-300);
          margin-left: 8px;
          border-radius: 8px;
        }
  
        .tiss-delete-icon {
          margin-left: 20px;
        }
      }
  
      .attached-file {
        display: inline-flex;
        align-items: center;
        background-color: #F4F5FB;
        padding: 8px 20px;
        border-radius: 8px;
        margin: -15px 8px 0 8px;
        color: var(--type-placeholder);
      }
  
      .tiss-remove-file-icon {
        margin-top: 2px;
        margin-left: 10px;
        width: 24px;
        height: 24px;
        fill: var(--neutral-500);
        cursor: pointer;
      }
  
      .around-content {
        padding: 24px 24px 0 24px;
  
        .multiselect {
          .multiselect__single {
            color: #525C7A !important;
          }
        }

        .use-patient-credit {
          label {
            color: #0C1D59;
            font-weight: bold;
          }
        }

        .credit-available {
          color: #069763;
          font-weight: bold;
        }
      }
  
    }
  
    .wrapper-button {
      width: 100%;
      display: inline-flex;
      margin: 20px 0 !important;
      justify-content: flex-end;
  
      &.deletable {
        justify-content: space-between;
      }
  
  
      button {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
  
        &.danger {
          color: var(--states-error);
        }
      }

      .view-invoice__button {
        font-weight: 700;
        background: transparent;
        color: var(--neutral-500);
        border-radius: $border-radius-base;
        border: 2px solid var(--neutral-500);
      }
    }
  }
  
  .description {
    margin-bottom: 20px;
  }
  
  
  
  #title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0C1D59;
  }
  
  #help {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: #8696AC;
  }
  
  #hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  
  #space-groups {
    display: flex;
    flex-direction: row;
    margin: 16px 0 0 0;
    width: 100%;
  }
  
  #space-groups-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  #red-border{
    border: 0.66px red solid !important;
    color: red !important;
  }

  .use-credit__label {
    font-size: 16px;
    font-weight: 400;
    color: #525C7A;
  }
</style>
  